export default function (item) {
  const itemTypesMapping = {
    'File': 'item-file',
    'News Item': 'item-news',
    'cka.event': 'item-event',
    'cka.page': 'item-page',
    'cka.section': 'item-page',
    'cka.project': 'item-project',
    'cka.competition': 'item-competition',
    'cover.card': 'item-card',
    'cka.publication': 'item-publication',
    'cka.official_desk_record': 'item-official-desk-record',
    'cka.labor_ad': 'item-labor-ad',
    Folder: 'item-page',
  }

  return item?.['@type']
    ? itemTypesMapping[item?.['@type']]
      ? itemTypesMapping[item?.['@type']]
      : 'item-page'
    : 'item-page'
}
